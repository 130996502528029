<script setup lang="ts">
import ErrorBar from '@/components/generic/ErrorBar.vue'
import ChatbotClearance from '@/components/generic/ChatbotClearance.vue'
import EditProfileModal from '@/components/generic/EditProfileModal.vue'
import useCookieConsent from '@/composables/useCookieConsent'

useCookieConsent()
</script>

<template>
  <ErrorBar />
  <router-view v-slot="{ Component }">
    <Transition name="page-fade" mode="out-in">
      <component :is="Component" />
    </Transition>
  </router-view>

  <ChatbotClearance />
  <EditProfileModal />
</template>

<style lang="scss">
@use '@/sass/main.scss';
</style>
