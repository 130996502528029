<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import menuItems from '@/components/generic/SideNav/menu-items.json'
import { CLOSE_MOBILE_MENU } from '@/store/mutation-types'

const store = useStore()
const router = useRouter()

const sidebarIsOpen = computed(() => store.getters.sidebarIsOpen)
const charityName = computed(() => store.getters.charityName)

const toUserDashboard = () => {
  store.commit(CLOSE_MOBILE_MENU)
  router.push({ name: 'Dashboard' })
}

const closeSideNav = () => {
  store.commit(CLOSE_MOBILE_MENU)
}
</script>

<template>
  <aside class="side-nav" :class="{ 'side-nav--open': sidebarIsOpen }">
    <ul class="side-nav__menu" data-testid="side-menu">
      <li class="side-nav__menu-item d-lg-none">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="side-nav__link sa-logo brand-link"
        >
          <div class="brand-link__image">
            <img
              :alt="$t('Generic.SideBar.NavLogoImageAlt')"
              src="@/assets/images/logo-sa.svg"
              width="28px"
              height="36px"
            />
          </div>
          <div class="brand-link__divider"></div>
          <div v-if="charityName" class="brand-link__charity-name">
            {{ charityName }}
          </div>
        </router-link>
      </li>

      <li
        v-for="item in menuItems"
        :key="item.name"
        class="side-nav__menu-item"
      >
        <router-link :to="{ name: item.to }" class="side-nav__link">
          <i :class="item.icon"></i>
          <span>{{ $t(item.name) }}</span>
        </router-link>
      </li>

      <li class="side-nav__menu-item" @click.prevent="toUserDashboard">
        <a class="side-nav__link">
          <i class="fa fa-home fa-fw"></i>
          <span>
            {{ $t('Generic.SideBar.MenuItems.Exit') }}
          </span>
        </a>
      </li>
    </ul>
    <div
      v-if="sidebarIsOpen"
      class="side-nav__overlay"
      @click="closeSideNav"
    ></div>
  </aside>
</template>

<style lang="scss">
.brand-link {
  display: flex;

  &__divider {
    border-left: solid 2px var(--mid-grey);
    height: 40px;
    margin: 0 calc(var(--spacer) * 0.5);
    float: left;
  }

  &__charity-name {
    font-size: 20px;
    font-family: var(--header-font-family);
    font-weight: 500;
    color: var(--black) !important;
  }

  &.side-nav__link:hover {
    background-color: var(--light-grey);
  }
}

.side-nav {
  position: absolute;
  inset: var(--navbar-height-sm) 0 0 0;
  width: 100%;
  z-index: 4;
  opacity: 0;

  &--open {
    opacity: 1;
  }

  @media (min-width: 992px) {
    position: initial;
    flex: 0 0 270px;
    margin: 20px 0 0 20px;
    opacity: 1;
  }

  &__menu {
    background: var(--white);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__menu-item {
    height: 56px;
    border-bottom: solid 1px var(--light-grey);
  }

  &__link {
    display: flex;
    align-items: center;
    height: var(--sidebar-link-height);
    padding-left: 25px;

    font: {
      family: var(--main-font-family);
      size: 17px;
      weight: 600;
    }
    color: var(--black);
    text-decoration: none;

    cursor: pointer;
    transition: all 0.12s linear;

    &:last-child {
      border: none;
    }

    i {
      color: var(--swiftaid-blue);
      padding-right: 35px;
    }

    &:hover,
    &.router-link-active {
      color: var(--white);
      background-color: var(--swiftaid-blue);

      i {
        color: var(--white);
      }
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: -1;
    background-color: rgba(var(--black-rgb), 0.5);

    @media (min-width: 992px) {
      display: none;
    }
  }
}
</style>
